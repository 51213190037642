<template>
  <div v-if="filteredFlats.length > 0" class="best">
    <div class="h2 best__title">Лучшие предложения</div>
    <Flats v-if="mq.lgPlus" :limit="limit" :flats="filteredFlats" />
    <FlatsMobile v-else :limit="limit" :flats="filteredFlats" />
    <div class="best__btn">
      <router-link :to="{ name: 'PlanSearch' }" class="btn btn-default">
        Посмотреть все предложения
      </router-link>
    </div>
  </div>
</template>

<script>
import Flats from "@/components/FlatsTable.vue";
import FlatsMobile from "@/components/FlatsTableMobile.vue";
import axios from "axios";
export default {
  components: {
    Flats,
    FlatsMobile,
  },
  inject: ["mq"],
  props: {
    limit: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  emits: ["flatsLoaded"],
  data() {
    return {
      best: [],
    };
  },
  computed: {
    filteredFlats() {
      const flats = this.best.filter((flat) => {
        return flat.layout.room_count === "S" && flat.floor.number > 1; // ???
      });

      return flats;
    },
  },
  async mounted() {
    try {
      const responseBest = await axios.get(
        this.$endpoint + "building-objects/" + this.$project + "/properties"
      );
      this.best = responseBest.data.data;
      this.$emit("flatsLoaded");
    } catch (err) {
    } finally {
    }
  },
};
</script>

<style scoped>
.best {
  margin-top: 120px;
}

.best__btn {
  margin-top: 35px;
}
@media (max-width: 991px) {
  .best__title {
    text-align: center;
  }

  .best__btn {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .best {
    margin-top: 60px;
  }

  .best__title {
    text-align: left;
  }

  .best__btn {
    text-align: left;
  }
}

@media (max-width: 375px) {
  .best__btn .btn {
    width: 100%;
  }
}
</style>
