<template>
  <Head>
    <title>Онлайн-покупка {{ $titleEnd }}</title>
    <meta name="description" content="Купите квартиру онлайн" />
    <meta property="og:title" :content="'Онлайн-покупка ' + $titleEnd" />
    <meta
      property="og:description"
      content="Купите квартиру онлайн"
    />
  </Head>
  <main class="main mb-0">
    <div class="container">
      <div class="page-content">
        <Breadcrumbs
          class="justify-content-center w-100"
          :links="[
            { name: 'Home', text: 'Главная' },
            { text: 'Онлайн-покупка' },
          ]"
        />
        <h1 class="page-title h2">
          Купите квартиру онлайн в&nbsp;несколько простых шагов:
        </h1>

        <div class="online-steps">
          <div
            v-for="step in steps"
            :key="step.title"
            class="online-steps__item"
          >
            <div ref="item" class="online-steps__inner">
              <div class="online-steps__image">
                <img
                  :src="step.image"
                  width="496"
                  height="360"
                  loading="lazy"
                  :alt="step.title"
                />
              </div>
              <div class="online-steps__title h3" v-html="step.title"></div>
              <div class="online-steps__text text-big" v-html="step.text"></div>
            </div>
            <div ref="dot" class="online-steps__dot"></div>
          </div>
          <div ref="line" class="online-steps__line">
            <div
              ref="lineActive"
              class="online-steps__line-active"
              :style="{ height: lineHeight + '%' }"
            ></div>
          </div>
        </div>
        <div ref="content" class="content">
          <div ref="text" class="text text-big">
            VERY полностью меняет восприятие жизни в&nbsp;Москве. Город здесь
            покоряется природе, становясь её&nbsp;необходимым и&nbsp;комфортным
            приложением.
          </div>
          <div ref="buttons" class="btns-list d-md-flex justify-content-center">
            <div class="btns-list__item">
              <router-link
                :to="{ name: 'PlanArchive' }"
                class="btn btn-default"
              >
                Выбрать квартиру
              </router-link>
            </div>
            <div class="btns-list__item">
              <button
                class="btn btn-transparent"
                data-popup-with-chats
                data-popup-with-chats-title="Связаться со&nbsp;специалистом"
              >
                Связаться со&nbsp;специалистом
              </button>
            </div>
          </div>
        </div>
      </div>

      <div ref="best" class="online-steps-best">
        <BestFlats :limit="5" @flatsLoaded="flatsLoaded = true" />
      </div>
    </div>
    <HomeQuestions v-if="flatsLoaded" />
  </main>
</template>

<script>
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import HomeQuestions from "@/components/home/Questions.vue";
import BestFlats from "@/components/BestFlats.vue";

import { is_touch } from "@/helpers.js";
import { Head } from "@vueuse/head";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  components: {
    Head,
    Breadcrumbs,
    HomeQuestions,
    BestFlats,
  },
  data() {
    return {
      flatsLoaded: false,
      lineHeight: 0,
      steps: [
        {
          image: require("@i/online-step-1.png"),
          title: "Получить онлайн-консультацию",
          text: "Выберите квартиру мечты не&nbsp;посещая офиса.",
        },
        {
          image: require("@i/online-step-2.png"),
          title: "Забронировать выбранный вариант",
          text: "Выбранный лот будет забронирован на&nbsp;Ваше&nbsp;имя.",
        },
        {
          image: require("@i/online-step-3.png"),
          title: "Выбрать способ оплаты",
          text: "Воспользуйтесь предложениями по&nbsp;ипотеке от&nbsp;банков партнеров, оформите рассрочку или оплатите всю сумму целиком.",
        },
        {
          image: require("@i/online-step-4.png"),
          title: "Подготовить договор",
          text: "Ваш персональный менеджер будет сопровождать Вас на&nbsp;протяжении всей сделки.",
        },
        {
          image: require("@i/online-step-5.png"),
          title: "Подписать договор",
          text: "Подпишите договор с&nbsp;помощью электронной подписи.",
        },
        {
          image: require("@i/online-step-6.png"),
          title: "Оплатить дистанционно",
          text: "Без необходимости визита в&nbsp;офиc.",
        },
      ],
    };
  },
  mounted() {
    if (!is_touch()) {
      gsap.utils.toArray(this.$refs.dot).forEach((dot) => {
        ScrollTrigger.create({
          trigger: dot,
          start: "top center",
          end: "bottom center",
          onEnter: () => {
            dot.classList.add("-active");
          },
          onLeaveBack: () => {
            dot.classList.remove("-active");
          },
        });
      });
      ScrollTrigger.create({
        trigger: this.$refs.line,
        start: "top center",
        end: "bottom center",
        onUpdate: (vaa) => {
          this.lineHeight = vaa.progress * 100;
        },
      });
      gsap.utils.toArray(this.$refs.item).forEach((item) => {
        gsap.set(gsap.utils.toArray(item.children), {
          autoAlpha: 0,
        });
        ScrollTrigger.create({
          trigger: item,
          start: "top center",
          end: "bottom center",
          onEnter: () => {
            gsap.fromTo(
              gsap.utils.toArray(item.children),
              {
                autoAlpha: 0,
                y: 100,
              },
              {
                autoAlpha: 1,
                y: 0,
                stagger: 0.1,
                ease: "power2.out",
              }
            );
          },
          onLeaveBack: () => {
            gsap.fromTo(
              gsap.utils.toArray(item.children),
              {
                autoAlpha: 1,
                y: 0,
              },
              {
                autoAlpha: 0,
                y: 100,
                stagger: 0.1,
                ease: "power2.out",
              }
            );
          },
        });
      });
      gsap.from([this.$refs.text, this.$refs.buttons, this.$refs.best], {
        y: 150,
        overwrite: "auto",
        scrollTrigger: {
          trigger: this.$refs.content,
          start: "top bottom",
          end: "bottom center",
          scrub: 1.5,
        },
        stagger: 0.1,
      });
    }
  },
};
</script>

<style scoped>
.page-title {
  max-width: 860px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.online-steps {
  position: relative;
  margin-top: 80px;
}

.online-steps__line {
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  width: 2px;
  height: calc(100% - 400px);
  margin-left: -1px;
  background-color: #f2f2f2;
  font-size: 0;
}

.online-steps__line-active {
  width: 100%;
  height: 0;
  background: #205640;
}

.online-steps__item {
  position: relative;
  z-index: 1;
  width: 50%;
  height: 400px;
}

.online-steps__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 496px;
}

.online-steps__dot {
  position: absolute;
  top: 0;
  left: 100%;
  width: 52px;
  height: 52px;
  margin-left: -26px;
  border-radius: 50%;
  background: #f8f8f8;
}

.online-steps__dot::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 0.05);
}

.online-steps__dot::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 50%;
  background: #d9dad9;
  transition: background 0.15s;
}

.online-steps__dot.-active::after {
  background: #205640;
}

.online-steps-best {
  margin-bottom: 120px;
}

@media (min-width: 768px) {
  .online-steps__item:nth-child(even) {
    margin-left: auto;
  }

  .online-steps__item:nth-child(even) .online-steps__inner {
    right: 0;
    left: auto;
  }

  .online-steps__item:nth-child(even) .online-steps__dot {
    right: 100%;
    left: auto;
    margin-right: -26px;
    margin-left: 0;
  }
}

.online-steps__image {
  position: relative;
  margin-bottom: 37px;
  padding-bottom: 72.581%;
}

.online-steps__image img {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .online-steps__image img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.online-steps__title {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .online-steps__title {
    line-height: 0.92;
  }
}

.content {
  margin-top: 250px;
  margin-bottom: 120px;
}

.text {
  max-width: 808px;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 1279px) {
  .online-steps__line {
    height: calc(100% - 325px);
  }

  .online-steps__item {
    height: 325px;
  }

  .online-steps__inner {
    width: 396px;
  }
}

@media (max-width: 991px) {
  .online-steps {
    margin-top: 60px;
  }

  .online-steps__line {
    height: calc(100% - 250px);
  }

  .online-steps__item {
    height: 250px;
  }

  .online-steps__inner {
    width: 296px;
  }
}

@media (max-width: 767px) {
  .online-steps {
    margin-top: 40px;
  }

  .online-steps__line {
    display: none;
  }

  .online-steps__item {
    width: auto;
    height: auto;
    margin-bottom: 60px;
  }

  .online-steps__inner {
    position: relative;
    width: auto;
  }

  .online-steps__dot {
    display: none;
  }

  .online-steps__image {
    max-width: 300px;
    margin-bottom: 15px;
    padding: 0;
  }

  .content {
    margin: 65px 0;
  }

  .btns-list {
    text-align: center;
  }

  .btns-list__item {
    margin-right: 0;
    margin-bottom: 10px;
    margin-left: 0;
  }

  .btns-list__item:last-child {
    margin-bottom: 0;
  }

  .online-steps-best {
    margin-bottom: 65px;
  }
}
</style>
